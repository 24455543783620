<template>
  <AppPageCard>
    <template #card-title> Anggaran Belanjawan Mengurus</template>
  </AppPageCard>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
export default {
  name: "ABMPage",
  components: { AppPageCard },
};
</script>

<style scoped></style>
